import { Z_BUSINESS_BENEFIT } from "constant/home";
import { CheckIcon, DownArrow } from "icons";
import Text from "components/text";
import cx from "utils/cx";
import { useState } from "react";
import home from "services/home";
const owlClass = "packages-benefit";
export default function ListBenefitPro({
  source,
  flow,
}: {
  source: string;
  flow: string;
}) {
  const [toggle, setToggle] = useState<boolean>(false);

  const onClickMoreInfo = () => {
    setToggle(!toggle);
    home.trackingLog({
      flow: flow || "Onboard Paywall",
      action: "click_more_info",
      params: JSON.stringify({ source }),
    });
  };

  return (
    <div
      className={cx(
        `${owlClass}__container ${owlClass}__container-benefit`,
        toggle && `${owlClass}__container-benefit__open`
      )}
    >
      <Text size="xlg-20" weight="semi-bold" className={`${owlClass}__heading`}>
        Đặc quyền nổi bật của zBusiness Pro
      </Text>
      <div className={`${owlClass}__benefit`}>
        {Z_BUSINESS_BENEFIT.pro.includes.map((include, idx) => (
          <div key={idx} className={`${owlClass}__benefit__item`}>
            <CheckIcon color="#3576EB" style={{ flexShrink: 0 }} />
            {include}
          </div>
        ))}
      </div>
      <div
        className={cx(
          `${owlClass}__extra_benefit`,
          toggle && `${owlClass}__open`
        )}
      >
        {Z_BUSINESS_BENEFIT.pro.extraIncludes.map((extra, idx) => (
          <div className={`${owlClass}__container`} key={idx}>
            <Text size="md" weight="semi-bold" className={`${owlClass}__title`}>
              {extra.title}
            </Text>
            {extra.includes.map((include, index) => (
              <div
                className={`${owlClass}__benefit`}
                key={`include_${idx}_${index}`}
              >
                <div className={`${owlClass}__benefit__item`}>
                  <CheckIcon color="#3576EB" style={{ flexShrink: 0 }} />
                  {include}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={`${owlClass}__toggle`} onClick={onClickMoreInfo}>
        <span>Xem tất cả các tính năng</span>
        <DownArrow className={cx(toggle && `${owlClass}__expand`)} />
      </div>
    </div>
  );
}
