import "./style.scss";

import Image from "components/image";
import SolutionInfo from "components/solution-info";
import React, { useEffect } from "react";
import introductionImg from "../../images/Introduction.png";

interface SolutionProps {}

const owlClass = "solution";

const ZIC_ZAC_SOLUTION = [
  {
    key: "SOLUTION-1",
    left: (
      <Image
        srcImage="https://res-zalo.zadn.vn/upload/media/2024/1/30/extend_contact_1706602699899_434042.png"
        width={550}
        height={440}
        loading="lazy"
        style={{
          maxWidth: "100%",
        }}
      />
    ),
    right: (
      <SolutionInfo
        topDescription="Gia tăng cơ hội tiếp cận khách hàng với"
        title={`Mở rộng danh bạ bạn bè`}
        btmDescription="Không còn nỗi lo danh bạ thiếu chỗ để kết bạn với
        khách hàng tiềm năng."
      />
    ),
  },

  {
    key: "SOLUTION-2",
    isGrey: true,
    isPushToRight: true,
    left: (
      <SolutionInfo
        topDescription="Tạo sự tin tưởng, uy tín với"
        title={`Nhãn nhận diện Business
          và Trang mở đầu`}
        btmDescription="Khách hàng luôn tìm kiếm những địa chỉ uy tín để liên hệ. Nhãn Business đính kèm tên Zalo nổi bật và Trang mở đầu chuyên nghiệp với đầy đủ các thông tin thiết yếu sẽ giúp bạn nhận được sự tin tưởng của khách hàng ngay từ lần trò chuyện đầu tiên."
      />
    ),
    right: (
      <Image
        srcImage={introductionImg}
        width={550}
        height={440}
        loading="lazy"
        style={{
          maxWidth: "100%",
        }}
      />
    ),
  },

  {
    key: "SOLUTION-3",
    isGrey: false,
    isPushToRight: true,
    left: (
      <Image
        srcImage="https://res-zalo.zadn.vn/upload/media/2024/1/30/auto_fast_messages_1706602819645_54621.png"
        width={550}
        height={440}
        loading="lazy"
        style={{
          maxWidth: "100%",
        }}
      />
    ),
    right: (
      <SolutionInfo
        topDescription="Tư vấn khách nhanh chóng, chuyên nghiệp với"
        title={`Tin nhắn tự động,
        tin nhắn nhanh`}
        btmDescription="Với tin nhắn tự động, bạn có thể trả lời khách hàng ngay cả khi vắng mặt bằng mẫu tin soạn sẵn. Ngoài ra, bạn còn có thể giảm thời gian soạn tin nhắn (nội dung lặp đi lặp lại) với mẫu tin nhắn nhanh."
      />
    ),
  },
  {
    key: "SOLUTION-4",
    isGrey: true,
    // isPushToRight: true,
    left: (
      <SolutionInfo
        topDescription="Kinh doanh hiệu quả hơn với"
        title={`Danh mục sản phẩm`}
        btmDescription="Chỉ cần tạo sản phẩm và bạn có thể lập tức chia sẻ tới khách hàng, không cần soạn lại nội dung mỗi lần tư vấn."
      />
    ),
    right: (
      <Image
        srcImage="https://res-zalo.zadn.vn/upload/media/2024/1/30/category_1706602881566_462176.png"
        width={550}
        height={440}
        loading="lazy"
        style={{
          maxWidth: "100%",
        }}
      />
    ),
  },
];

const Solution: React.FC<SolutionProps> = () => {
  const solutionsRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const solution = params.get("solution");
    if (!solution) {
      return;
    }
    const timeOut = setTimeout(() => {
      if (solutionsRef.current) {
        const secondChild = solutionsRef.current.children[+solution];
        if (secondChild) {
          secondChild.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    }, 150);

    return () => clearTimeout(timeOut);
  }, []);
  return (
    <div className={owlClass} ref={solutionsRef}>
      {ZIC_ZAC_SOLUTION.map(({ key, isGrey, isPushToRight, left, right }) => (
        <div
          key={key}
          className={
            isGrey
              ? `${owlClass}__container--grey ${owlClass}__container`
              : `${owlClass}__container`
          }
        >
          <div className={`${owlClass}__container__zic-zac`}>
            <div
              className={
                isPushToRight
                  ? `${owlClass}__container__zic-zac__left`
                  : `${owlClass}__container__zic-zac__left ${owlClass}__container__zic-zac__left--image`
              }
            >
              {left}
            </div>
            <div
              className={
                isPushToRight
                  ? `${owlClass}__container__zic-zac__right ${owlClass}__container__zic-zac__right--image`
                  : `${owlClass}__container__zic-zac__right`
              }
            >
              {right}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Solution;
