import { REQUEST_TIMEOUT } from "constant/request-errors";
import type { PostParams, ReturnedData } from "./model";
import "whatwg-fetch";

const toQueryString = (object: Record<string, any>) =>
  new URLSearchParams(object).toString();

const postApi = async <T = any>({
  domain = import.meta.env.VITE_API,
  path = "",
  success,
  error,
  query,
  body,
  timeout = REQUEST_TIMEOUT,
}: PostParams<T>): Promise<ReturnedData<T>> => {
  let controller;
  try {
    controller = new AbortController();
  } catch (err) {
    controller = null;
  }
  const idTimeout = setTimeout(() => controller?.abort(), timeout);
  try {
    const resp = await fetch(
      `${domain}${path}${query ? `?${toQueryString(query)}` : ""}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
        signal: controller?.signal,
      }
    );
    clearTimeout(idTimeout);
    if (!resp.ok) {
      throw new TypeError(
        `Fetched error status: ${resp.status} ${resp.statusText}`
      );
    }
    const data = await resp.json();
    success?.(data || {});
    return data || {};
  } catch (ex) {
    error?.(ex);
    return {};
  }
};

export default postApi;
