import "./style.scss";

import Label from "components/label";
import RegisterPackage from "components/register-package";
import Text from "components/text";
import Title from "components/title";
import Tooltip from "components/tooltip";
import { IS_TRIAL, Z_BUSINESS } from "constant/home";
import { CheckIcon, NoneIcon } from "icons";
import React from "react";

interface ComparePackagesProps {}

const owlClass = "compare-packages";

const ComparePackages: React.FC<ComparePackagesProps> = () => (
  <div className={owlClass}>
    <table className={`${owlClass}__table`}>
      <thead>
        <tr>
          <th></th>
          <th>
            <div>
              <Text size="xlg-24" weight="three-quarters-bold">
                {`Người dùng
            thường`}
              </Text>
            </div>
          </th>
          <th>
            <Title size="h3">Basic</Title>
            <div
              className={`${owlClass}__table__product ${owlClass}__table__product--price`}
            >
              <Text color="secondary">Miễn phí</Text>
            </div>
            <div className={`${owlClass}__table__product`}>
              <RegisterPackage
                isTrial={Z_BUSINESS.packages[0].packageId === IS_TRIAL}
                name={Z_BUSINESS.packages[0].name}
                products={Z_BUSINESS.packages[0].products.filter(
                  (prod) => !prod.hidden
                )}
                size="small"
                source="landing-page"
              />
            </div>
          </th>
          <th>
            <Title size="h3">Standard</Title>
            <div
              className={`${owlClass}__table__product ${owlClass}__table__product--price`}
            >
              <Text color="secondary">đ99.000/tháng</Text>
            </div>
            <div className={`${owlClass}__table__product`}>
              <RegisterPackage
                isTrial={Z_BUSINESS.packages[1].packageId === IS_TRIAL}
                name={Z_BUSINESS.packages[1].name}
                products={Z_BUSINESS.packages[1].products.filter(
                  (prod) => !prod.hidden
                )}
                size="small"
                source="landing-page"
              />
            </div>
          </th>
          <th>
            <Title size="h3">Pro</Title>
            <div
              className={`${owlClass}__table__product ${owlClass}__table__product--price`}
            >
              <Text color="secondary">Từ 166.000đ/ tháng</Text>
            </div>
            <div className={`${owlClass}__table__product`}>
              <RegisterPackage
                isTrial={Z_BUSINESS.packages[2].packageId === IS_TRIAL}
                name={Z_BUSINESS.packages[2].name}
                products={Z_BUSINESS.packages[2].products.filter(
                  (prod) => !prod.hidden
                )}
                size="small"
                source="landing-page"
              />
            </div>
            {/* <Button
            size="small"
            type="primary"
            style={{
              margin: "0 auto",
            }}
          >
            Đăng ký gói
          </Button> */}
          </th>
        </tr>
      </thead>
      <tbody>
        {/* Mở rộng mạng lưới khách hàng */}
        <tr>
          <td>
            <Text size="xlg-22" weight="three-quarters-bold">
              Mở rộng mạng lưới khách hàng
            </Text>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Danh bạ bạn bè</td>
          <td>
            3,000
            <div>(như hiện tại)</div>
          </td>
          <td>Không đổi</td>
          <td>5,000</td>
          <td>
            <div className="last-td-wrapper">5,000</div>
          </td>
        </tr>
        {/* <tr>
          <td>
            Cài đặt cho người lạ xem nhật ký cá nhân{' '}
            <Tooltip content='Người dùng có thể cài đặt chế độ cho Nhật ký cá nhân: ẩn hoặc cho phép người khác xem và bình luận.' />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <CheckIcon color='#3576EB' />
          </td>
          <td>
            <div className='last-td-wrapper'>
              <CheckIcon color='#3576EB' />
            </div>
          </td>
        </tr> */}
        <tr>
          <td>
            Link liên hệ (shortlink){" "}
            <Tooltip content="Tạo đường link sử dụng số điện thoại của tài khoản để truy cập nhanh" />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>Số điện thoại</td>
          <td>Số điện thoại</td>
          <td>
            <div className="last-td-wrapper">Số điện thoại</div>
          </td>
        </tr>
        <tr>
          <td>Nhận tin nhắn và cuộc gọi từ người lạ</td>
          <td>
            <CheckIcon color="#3576EB" />
          </td>
          <td>
            <CheckIcon color="#3576EB" />
          </td>
          <td>
            <CheckIcon color="#3576EB" />
          </td>
          <td>
            <div className="last-td-wrapper">
              <CheckIcon color="#3576EB" />
            </div>
          </td>
        </tr>
        <tr>
          <td>Số nhóm được tạo và tham gia</td>
          <td>Không đổi</td>
          <td>Không đổi</td>
          <td>Không đổi</td>
          <td>
            <div className="last-td-wrapper">2,000</div>
          </td>
        </tr>
        <tr>
          <td>Số lượng cộng đồng được quản lý</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>
            <div className="last-td-wrapper">10</div>
          </td>
        </tr>
        {/* Xay dung uy tinh */}
        <tr>
          <td
            style={{
              paddingTop: 56,
            }}
          >
            <Text size="xlg-22" weight="three-quarters-bold">
              Xây dựng uy tín
            </Text>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        {/* <tr>
          <td>
            Hồ sơ kinh doanh{' '}
            <Tooltip content='Các đề mục mở rộng trong profile cá nhân, cung cấp thông tin kinh doanh chi tiết hơn' />
          </td>{' '}
          <td>
            <NoneIcon />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <CheckIcon color='#3576EB' />
          </td>
          <td>
            <div className='last-td-wrapper'>
              <CheckIcon color='#3576EB' />
            </div>
          </td>
        </tr> */}
        <tr>
          <td>
            Nhãn nhận diện tài khoản kinh doanh{" "}
            <Tooltip content="Bật hiển thị để người khác có thể nhìn thấy nhãn nhận diện tài khoản zBusiness của bạn" />
          </td>
          <td>
            {/* <NoneIcon /> */}
            Không hiển thị cho người khác
          </td>
          <td>
            {/* <NoneIcon /> */}
            Không hiển thị cho người khác
          </td>
          <td>
            {/* <NoneIcon /> */}
            Không hiển thị cho người khác
          </td>
          <td>
            <div className="last-td-wrapper">
              <Label text="Business" />
            </div>
          </td>
        </tr>

        {/* Hỗ trợ giao tiếp và bán hàng */}
        <tr>
          <td
            style={{
              paddingTop: 56,
            }}
          >
            <Text size="xlg-22" weight="three-quarters-bold">
              Hỗ trợ giao tiếp và bán hàng
            </Text>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            Thông báo tin nhắn từ người lạ{" "}
            <Tooltip content="Tin nhắn từ người lạ không bị lọc vào nhóm 'Người lạ'. Giảm khả năng bỏ lỡ tin nhắn." />
          </td>
          <td>
            <CheckIcon color="#3576EB" />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <CheckIcon color="#3576EB" />
          </td>
          <td>
            <div className="last-td-wrapper">
              <CheckIcon color="#3576EB" />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            Tin nhắn nhanh (Quick message){" "}
            <Tooltip content="Gửi nhanh một tin nhắn mà bạn đã soạn bằng cách nhập cú pháp, từ khoá" />
          </td>
          <td>1</td>
          <td>3</td>
          <td>10</td>
          <td>
            <div className="last-td-wrapper">50</div>
          </td>
        </tr>
        <tr>
          <td>Tin nhắn trả lời tự động (Auto reply)</td>
          <td>
            <NoneIcon />
          </td>
          <td>1</td>
          <td>5</td>
          <td>
            <div className="last-td-wrapper">20</div>
          </td>
        </tr>
        <tr>
          <td>
            Gửi tin nhắn đồng loạt (trên Zalo PC){" "}
            <Tooltip content="Gửi mẫu tin nhắn tới nhiều người nhận cùng một lúc, bao gồm cả định dạng hình ảnh" />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>Cho 30 người</td>
          <td>
            <div className="last-td-wrapper">Cho 50 người</div>
          </td>
        </tr>
        <tr>
          <td>Mở nhiều cửa sổ chat (trên Zalo PC)</td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <CheckIcon color="#3576EB" />
          </td>
          <td>
            <div className="last-td-wrapper">
              <CheckIcon color="#3576EB" />
            </div>
          </td>
        </tr>
        <tr>
          <td>Danh mục sản phẩm (Catalog)</td>
          <td>
            <NoneIcon />
          </td>
          <td>2 sản phẩm</td>
          <td>10 sản phẩm</td>
          <td>
            <div className="last-td-wrapper">100 sản phẩm</div>
          </td>
        </tr>
        <tr>
          <td>Quản lý thẻ phân loại khách hàng trên điện thoại</td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>Sắp ra mắt</td>
          <td>
            <div className="last-td-wrapper">Sắp ra mắt</div>
          </td>
        </tr>
        <tr>
          <td
            style={{
              paddingTop: 56,
            }}
          >
            <Text size="xlg-22" weight="three-quarters-bold">
              Quyền lợi khác
            </Text>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Ưu tiên hỗ trợ chăm sóc khách hàng</td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <CheckIcon color="#3576EB" />
          </td>
          <td>
            <div className="last-td-wrapper">
              <CheckIcon color="#3576EB" />
            </div>
          </td>
        </tr>
        <tr>
          <td>Ưu tiên trải nghiệm tính năng mới</td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <NoneIcon />
          </td>
          <td>
            <CheckIcon color="#3576EB" />
          </td>
          <td>
            <div className="last-td-wrapper">
              <CheckIcon color="#3576EB" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default ComparePackages;
