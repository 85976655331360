import { IconProps } from "./model";
const CopyIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || 14}
    height={size || 14}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00497 1.375C4.75852 1.375 4.55873 1.57051 4.55873 1.81168C4.55873 2.05285 4.75852 2.24835 5.00497 2.24835H10.5543C10.9979 2.24835 11.3575 2.60027 11.3575 3.03437V10.7711C11.3575 11.0123 11.5573 11.2078 11.8038 11.2078C12.0502 11.2078 12.25 11.0123 12.25 10.7711V3.03437C12.25 2.11793 11.4908 1.375 10.5543 1.375H5.00497ZM3.53496 4.95034H7.69988C8.19278 4.95034 8.59236 5.34135 8.59236 5.82369V12.2283C8.59236 12.7106 8.19279 13.1016 7.69988 13.1016H3.53496C3.04206 13.1016 2.64248 12.7106 2.64248 12.2283V5.82369C2.64248 5.34135 3.04206 4.95034 3.53496 4.95034ZM1.75 5.82369C1.75 4.85901 2.54916 4.07699 3.53496 4.07699H7.69988C8.68569 4.07699 9.48485 4.85901 9.48485 5.82369V12.2283C9.48485 13.193 8.68569 13.975 7.69988 13.975H3.53496C2.54916 13.975 1.75 13.193 1.75 12.2283V5.82369Z"
      fill="#075AD5"
      stroke="#075AD5"
      strokeWidth={0.35}
    />
  </svg>
);
export default CopyIcon;
