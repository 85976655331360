import { IconProps } from "./model";

const CheckCircleIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || 20}
    height={size || 20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.37497 10C1.37497 5.23654 5.23651 1.375 9.99997 1.375C14.7634 1.375 18.625 5.23654 18.625 10C18.625 14.7635 14.7634 18.625 9.99997 18.625C5.23651 18.625 1.37497 14.7635 1.37497 10ZM14.5303 7.78032C14.8232 7.48743 14.8232 7.01255 14.5303 6.71966C14.2374 6.42677 13.7625 6.42677 13.4696 6.71966L8.37497 11.8143L6.4053 9.84466C6.11241 9.55177 5.63753 9.55177 5.34464 9.84466C5.05175 10.1376 5.05175 10.6124 5.34464 10.9053L7.84464 13.4053C8.13753 13.6982 8.61241 13.6982 8.9053 13.4053L14.5303 7.78032Z"
      fill="#32A458"
    />
  </svg>
);
export default CheckCircleIcon;
