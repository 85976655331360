// components
import Error from "components/error";
// pages
import Layout from "components/layout";
import LogoZBusiness from "components/logo-zbusiness";
import AnotherPaywall from "pages/another-paywall";
import ManageBasic from "pages/manage-basic/manage-basic";
import Paywall from "pages/paywall/paywall";
import PromotionVoucherPaywall from "pages/promotion-voucher-paywall";
import ServiceAgreement from "pages/service-agreement";

// import TrialPaywall from "pages/trial-paywall";

const routes = [
  // { key: "home", path: "/:sectionId", element: <Home /> },
  {
    key: "service-agreement",
    path: "/service-agreement",
    exact: true,
    element: <ServiceAgreement />,
  },
  {
    key: "paywall",
    path: "/nang-cap-business",
    exact: true,
    element: <Paywall />,
  },
  {
    key: "another-paywall",
    path: "/nang-cap-business-pro",
    exact: true,
    element: <AnotherPaywall />,
  },

  {
    key: "voucher",
    path: "/voucher",
    exact: true,
    element: <PromotionVoucherPaywall />,
  },
  // {
  //   key: "trial-paywall",
  //   path: "/trial",
  //   exact: true,
  //   element: <TrialPaywall />,
  // },

  {
    key: "upgrade-basic",
    path: "/upgrade-basic",
    exact: true,
    element: <ManageBasic />,
  },

  {
    key: "downgrade-basic",
    path: "/downgrade-basic",
    exact: true,
    element: <ManageBasic />,
  },

  {
    key: "manage-basic",
    path: "/manage-basic",
    exact: true,
    element: <ManageBasic />,
  },

  {
    key: "not-found",
    path: "/*",
    exact: true,
    element: (
      <Layout header={<LogoZBusiness />}>
        <Error title="Không tìm thấy trang này" />
      </Layout>
    ),
  },
];

export default routes;
