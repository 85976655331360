import { getApi, postApi } from "apis";
import {
  IActivePackageResp,
  IDowngradeBasicResp,
} from "constant/type-data-response";
import detectMobile from "hooks/detect-mobile";
import { getValueFromSessionStorage } from "utils/local-storage";
/* eslint-disable class-methods-use-this */
import { removeNullValueObj } from "utils/remove-null-obj";

import type { SubscriptionI } from "z-types/subscription";

class HomeService {
  buyBusinessTrial = async (userId: string) => {
    const rawResponse = await window.fetch(
      `${import.meta.env.VITE_ZBOX_DOMAIN_API}/zbusiness/basic`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      }
    );
    const res = await rawResponse.json();
    return res;
  };

  getBrowser = () => {
    const test = (regexp: RegExp) => {
      return regexp.test(navigator.userAgent);
    };
    if (test(/opr\//i)) {
      return "Opera";
    } else if (test(/zalopc/i)) {
      return "Zalo PC";
    } else if (test(/edg/i)) {
      return "Microsoft Edge";
    } else if (test(/chrome|chromium|crios/i)) {
      return "Google Chrome";
    } else if (test(/firefox|fxios/i)) {
      return "Mozilla Firefox";
    } else if (test(/safari/i)) {
      return "Apple Safari";
    } else if (test(/trident/i)) {
      return "Microsoft Internet Explorer";
    } else if (test(/ucbrowser/i)) {
      return "UC Browser";
    } else if (test(/samsungbrowser/i)) {
      return "Samsung Browser";
    }
    return "Unknown browser";
  };

  arrayToObject = (valueArr: URLSearchParams) => {
    const ret: Record<string, string> = {};
    for (const [key, value] of Array.from(valueArr.entries())) {
      ret[key] = value;
    }
    return ret;
  };

  getHostnameFromUrl(value: string): string {
    if (!value) return "";

    const match = value.match(/^(?:https?:\/\/)?(?:www\.)?([^/\s:]+)/i);
    return match?.[1] || "";
  }

  trackingLog = async (payload: {
    flow: string;
    action: string;
    params?: string;
  }) => {
    try {
      let paramsUrl = this.arrayToObject(
        new URLSearchParams(window.location.search)
      );
      const data = getValueFromSessionStorage("referer");
      let referer = "";
      if (data === null) {
        referer = document.referrer;
      } else {
        referer = data.referer;
        paramsUrl = data.params;
      }
      if (referer) {
        referer = this.getHostnameFromUrl(referer);
      }
      const platform = detectMobile() ? 0 : 1;
      await postApi<void>({
        path: `/track`,
        body: removeNullValueObj({
          ...payload,
          user: window.user,
          device: window.navigator.platform,
          browser: this.getBrowser(),
          platform,
          ref: referer,
          query: paramsUrl || {},
        }),
      });
    } catch (err) {
      console.error(err);
    }
  };

  getSubscription = async (payload: { userId: string }) => {
    const subs = await postApi<SubscriptionI>({
      path: `/get-subscription`,
      body: removeNullValueObj({
        ...payload,
      }),
    });
    return subs;
  };

  getActivePackage = async (payload: { userId: string }) => {
    try {
      const data = await getApi<IActivePackageResp>({
        path: `/get-active-pkg`,
        query: payload,
      });
      return data;
    } catch (error) {
      console.error("Error fetching active package:", error);
    }
  };

  downgradeBasic = async (payload: { userId: string }) => {
    try {
      const data = await postApi<IDowngradeBasicResp>({
        path: `/req-downgrade-basic`,
        body: removeNullValueObj({
          ...payload,
        }),
      });
      return data;
    } catch (error) {
      console.error("Error fetching active package:", error);
    }
  };

  getUtmTracking = () => {
    let paramsUrl = this.arrayToObject(
      new URLSearchParams(window.location.search)
    );
    const data = getValueFromSessionStorage("referer");
    let referer;
    if (data === null) {
      referer = document.referrer;
    } else {
      referer = data.referer;
      paramsUrl = data.params;
    }
    const utmSource = paramsUrl?.utm_source || "";
    const utmMedium = paramsUrl?.utm_medium || "";
    const utmCampaign = paramsUrl?.utm_campaign || "";
    if (referer) {
      referer = this.getHostnameFromUrl(referer);
    }
    return {
      ref: referer,
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    };
  };

  requestProductPayment = async (payload: {
    productId: string;
    userId: string;
    promotionId?: string;
  }) => {
    const rawResponse = await window.fetch(
      `${import.meta.env.VITE_API}/req-payment`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          removeNullValueObj({
            ...payload,
            tracking: this.getUtmTracking(),
          })
        ),
      }
    );
    const res = await rawResponse.json();
    return res;
  };

  requestCodeProductPayment = async (payload: {
    productId: string;
    qty: number;
    userId: string;
    confirm?: boolean;
    sendOA?: boolean;
  }) => {
    const rawResponse = await window.fetch(
      `${import.meta.env.VITE_API}/req-payment-code`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          removeNullValueObj({
            ...payload,
            tracking: this.getUtmTracking(),
          })
        ),
      }
    );
    const res = await rawResponse.json();
    return res;
  };

  checkErrorCodePayment = async (payload: {
    params: string;
    userId: string;
  }) => {
    const rawResponse = await window.fetch(
      `${import.meta.env.VITE_API}/get-information`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...payload }),
      }
    );
    const res = await rawResponse.json();
    return res;
  };

  checkValidPaywall = async (payload: {
    productId: string;
    promotionId: string;
  }) => {
    const res = await getApi<any>({
      path: "/check-valid-paywall",
      query: payload,
    });
    return res;
  };

  checkValidPromotion = async (payload: {
    productId: string;
    promotionId: string;
    userId: string;
  }) => {
    const res = await postApi({
      path: "/check-valid-promotion",
      body: payload,
    });
    return res;
  };
}

export default new HomeService();
