import Label from "components/label";
import Text from "components/text";
import { PackageDispI } from "z-types/home";

export const DESKTOP_SIZE = 1132;
export const PC_AGENT = "ZaloPC";

export type SectionTypes =
  // home page
  | "homepage-breadcrumb"
  | "homepage-scroll"
  | "homepage-logo"
  | "homepage"
  // prices
  | "price-breadcrumb"
  | "price-url"
  | "explore-homepage"
  | "explore-faq"
  | "price-scroll"
  // compare packages
  | "compare-packages"
  // solution
  | "solution-breadcrumb"
  | "solution-scroll"
  | "solution-url"
  // faq
  | "faq-breadcrumb"
  | "faq-scroll"
  | "faq-url"
  | null;

export type ModalType = {
  title?: string;
  description: React.ReactNode;
  okBtn?: React.ReactNode | React.ReactNode[];
};

export const BREADCRUMB = [
  {
    label: "Trang chủ",
    navigate: "homepage-breadcrumb",
  },
  {
    label: "Giá gói",
    navigate: "price-breadcrumb",
  },
  {
    label: "Giải pháp",
    navigate: "solution-breadcrumb",
  },
  {
    label: "FAQ",
    navigate: "faq-breadcrumb",
  },
];

export const IS_TRIAL = "IS_TRIAL";

export const Z_BUSINESS = {
  packages: [
    {
      packageId: IS_TRIAL,
      name: "Basic",
      priceDuration: "Miễn phí",
      payType: "Gói trải nghiệm cho nhu cầu cơ bản",
      includes: [
        <Text size="md">3 mẫu tin nhắn nhanh</Text>,
        <Text size="md">1 mẫu tin nhắn trả lời tự động</Text>,
        <Text size="md">Link liên hệ</Text>,
        <Text size="md">Danh mục sản phẩm (catalog)</Text>,
        <Text size="md">Được quản lý 1 cộng đồng</Text>,
      ],
      products: [],
    },
    {
      isRecommend: true,
      packageId: "standard",
      name: "Standard",
      priceDuration: "đ99.000/ tháng​",
      plan: "100GB_Yearly",
      extraLabels: [
        <Text color="secondary">Gói năm: đ83.000/ tháng </Text>,
        <Text color="selective-yellow">(Tiết kiệm 17%)</Text>,
      ],
      payType: "Dành cho người bán hàng và kinh doanh nhỏ",
      includes: [
        <Text size="md">10 mẫu tin nhắn nhanh</Text>,
        <Text size="md">5 mẫu tin nhắn trả lời tự động</Text>,
        <Text size="md">Link liên hệ</Text>,
        <Text size="md">Danh mục sản phẩm (catalog)</Text>,
        <Text size="md">Mở rộng danh bạ lên 5,000 bạn bè</Text>,
        <Text size="md">Gửi tin nhắn đồng loạt cho 30 người (Zalo PC)</Text>,
        <Text size="md">Mở nhiều cửa sổ chat (Zalo PC)</Text>,
      ],
      products: [
        {
          productId: "zap-z8559d0t",
          duration: 12,
          price: "đ990.000",
          rawPrice: 990000,
          savePercent: "Tiết kiệm 17%",
          oldPrice: "1.188.000đ",
          canBuyCode: false,
          hidden: false,
        },
        {
          productId: "zap-z8559d96",
          duration: 1,
          price: "đ99.000",
          rawPrice: 99000,
          savePercent: "",
          oldPrice: "",
          canBuyCode: false,
          hidden: false,
        },
      ],
    },
    {
      isRecommend: true,
      packageId: "pro",
      name: "Pro",
      priceDuration: "Từ 166.000đ/ tháng​",
      extraLabels: [
        // <Text color="secondary">Gói năm: 166.000đ/ tháng </Text>,
        // <Text color="selective-yellow">(Tiết kiệm 17%)</Text>,
      ],
      payType: "Dành cho người kinh doanh chuyên nghiệp",
      includes: [
        <div>
          <Text size="md"> Nhãn nhận diện tài khoản kinh doanh</Text>
          <Label
            text="Business"
            style={{
              marginLeft: 4,
            }}
          />
        </div>,
        <Text size="md">Mở rộng danh bạ lên 5,000 bạn bè</Text>,
        <Text size="md">Được tạo và tham gia lên tới 2,000 nhóm</Text>,
        <Text size="md">Được quản lý lên tới 10 cộng đồng</Text>,
        <div>
          <Text size="md">Trang mở đầu cửa sổ chat</Text>
          <Label
            type="hot"
            text="MỚI"
            style={{
              marginLeft: 4,
            }}
          />
        </div>,
        <Text size="md">50 mẫu tin nhắn nhanh</Text>,
        <Text size="md">20 mẫu tin nhắn trả lời tự động</Text>,
        <Text size="md">Nhận tin nhắn và cuộc gọi từ người lạ</Text>,
        <Text size="md">Link liên hệ</Text>,
        <Text size="md">Danh mục sản phẩm (catalog)</Text>,
        <Text size="md">Gửi tin nhắn đồng loạt cho 50 người (Zalo PC)</Text>,
        <Text size="md">Mở nhiều cửa sổ chat (Zalo PC)</Text>,
        <Text size="md">Ưu tiên hỗ trợ chăm sóc khách hàng</Text>,
      ],
      products: [
        // {
        //   productId: "zap-z85595t8",
        //   duration: 12,
        //   price: "1.999.000đ",
        //   rawPrice: 1999000,
        //   savePercent: "Tiết kiệm 17%",
        //   oldPrice: "2.388.000đ",
        //   canBuyCode: true,
        //   promotionText: "166.000đ/ tháng",
        //   hidden: false,
        // },
        {
          productId: "zap-z8559d0z",
          duration: 12,
          price: "1.990.000đ",
          rawPrice: 1990000,
          savePercent: "Tiết kiệm 17%",
          oldPrice: "2.388.000đ",
          canBuyCode: true,
          promotionText: "166.000đ/ tháng",
          hidden: false,
        },
        {
          productId: "zap-z8559d97",
          duration: 1,
          price: "199.000đ",
          rawPrice: 199000,
          savePercent: "",
          oldPrice: "",
          canBuyCode: false,
          hidden: false,
        },
      ],
    },
  ],
};

export const Z_BUSINESS_BENEFIT = {
  basic: {
    includes: [
      <Text size="md">Sở hữu 1 cộng đồng</Text>,
      <Text size="md">3 mẫu tin nhắn nhanh</Text>,
      <Text size="md">1 mẫu tin nhắn trả lời tự động</Text>,
    ],
    extraIncludes: [
      {
        title: "",
        includes: [
          <Text size="md">Sở hữu 1 cộng đồng</Text>,
          <Text size="md">2 sản phẩm danh mục</Text>,
          <Text size="md">3 mẫu tin nhắn nhanh</Text>,
          <Text size="md">1 mẫu tin nhắn trả lời tự động</Text>,
          <Text size="md">Link liên hệ</Text>,
        ],
      },
    ],
  },
  pro: {
    includes: [
      <div>
        <Text size="md">Mở rộng danh bạ lên 5,000 bạn</Text>
      </div>,
      <div>
        <Text size="md">Nhãn nhận diện tài khoản kinh doanh</Text>
        <Label
          text="Business"
          style={{
            marginLeft: 8,
          }}
        />
      </div>,
      <Text size="md">Tạo và tham gia tới 2,000 nhóm</Text>,
      <Text size="md">Sở hữu tới 10 cộng đồng</Text>,
      <div>
        <Text size="md">Trang mở đầu tại cửa sổ chat</Text>
        <Label
          type="hot"
          text="MỚI"
          style={{
            marginLeft: 8,
          }}
        />
      </div>,
      <Text size="md">20 mẫu tin nhắn trả lời tự động</Text>,
    ],
    extraIncludes: [
      {
        title: "Mở rộng mạng lưới khách hàng",
        includes: [
          // <Text size='md'>Cài đặt cho người lạ xem nhật ký cá nhân</Text>,
          <Text size="md">Link liên hệ</Text>,
          <Text size="md">Nhận tin nhắn và cuộc gọi từ người lạ</Text>,
        ],
      },
      // {
      //   title: 'Xây dựng uy tín',
      //   includes: [<Text size='md'>Hồ sơ kinh doanh chuyên nghiệp</Text>],
      // },
      {
        title: "Hỗ trợ giao tiếp và bán hàng",
        includes: [
          <Text size="md">Thông báo tin nhắn từ người lạ</Text>,
          <Text size="md">50 mẫu tin nhắn nhanh </Text>,
          <Text size="md">20 mẫu tin nhắn trả lời tự động</Text>,
          <Text size="md">100 sản phẩm danh mục</Text>,
          <Text size="md">
            Gửi tin nhắn đồng loạt đến 50 người (trên Zalo PC)
          </Text>,
          <Text size="md">Mở đồng thời nhiều cửa sổ chat (trên Zalo PC)</Text>,
        ],
      },
      {
        title: "Quyền lợi khác",
        includes: [
          <Text size="md">Ưu tiên hỗ trợ chăm sóc khách hàng</Text>,
          <Text size="md">Ưu tiên trải nghiệm tính năng mới</Text>,
        ],
      },
    ],
  },
};

export const Z_BUSINESS_PRO_TRIAL = {
  packageId: "pro-trial",
  name: "Trial",
  label: "Dùng thử zBusiness Pro",
  priceLabel: "10.000đ",
  caption:
    "Sau 7 ngày gia hạn tự động gói Pro 12 tháng giá 1.990.000đ. Huỷ bất cứ lúc nào",
  ctaBtn: "Dùng thử 7 ngày",
  productId: "zap-z85595t1",
  rawPriceLabel: "38.000đ/ 7ngày",
};

export const Z_BUSINESS_LITE: PackageDispI[] = [
  {
    packageId: IS_TRIAL,
    name: "Basic",
    label: "Basic",
    benefit: "basic",
    priceLabel: "Miễn phí",
    caption: "Gói trải nghiệm cho nhu cầu cơ bản",
    ctaBtn: "Dùng thử miễn phí",
    selBtn: {
      index: 0,
      text: "Gói trải nghiệm",
    },
    productId: "zap-z8559n76",
    hidden: false,
  },
  {
    isRecommend: true,
    packageId: "pro-1-month",
    name: "Pro",
    label: "Pro 1 tháng",
    benefit: "pro",
    priceLabel: "199.000đ",
    caption: "Dành cho người kinh doanh chuyên nghiệp",
    ctaBtn: "Đăng ký ngay • Gói 1 tháng",
    productId: "zap-z8559d97",
    selBtn: {
      index: 1,
      text: "Gói tháng",
    },
    hidden: false,
  },
  // {
  //   isRecommend: true,
  //   packageId: "pro-12-month",
  //   name: "Pro",
  //   label: "Pro 12 tháng",
  //   benefit: "pro",
  //   priceLabel: "166.000đ",
  //   extraLabel: "1.999.000đ cho 12 tháng",
  //   caption: "Dành cho người kinh doanh chuyên nghiệp",
  //   ctaBtn: "Đăng ký ngay • Gói 12 tháng",
  //   productId: "zap-z85595t8",
  //   selBtn: {
  //     index: 0,
  //     text: "Gói năm",
  //     badge: "-17%",
  //   },
  //   hidden: false
  // },
  {
    isRecommend: true,
    packageId: "pro-12-month",
    name: "Pro",
    label: "Pro 12 tháng",
    benefit: "pro",
    priceLabel: "166.000đ",
    extraLabel: "1.990.000đ cho 12 tháng",
    caption: "Dành cho người kinh doanh chuyên nghiệp",
    ctaBtn: "Đăng ký ngay • Gói 12 tháng",
    productId: "zap-z8559d0z",
    selBtn: {
      index: 0,
      text: "Gói năm",
      badge: "-17%",
    },
    hidden: false,
  },
];

export const TRIAL_PRODUCT_ID = "zap-z8559n76";
