import Button from "components/button";
import "./style.scss";

import Layout from "components/layout";

import ListBenefitPro from "components/packages-benefit/list-benefit-pro";
import Section from "components/section";
import Spin from "components/spin";
import Text from "components/text";
import { PC_AGENT } from "constant/home";
import { useAppSelector } from "hooks";

import CopyIcon from "icons/CopyIcon";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import home from "services/home";
import { RootState, store } from "store";
import { onFetchProfile } from "store/profile";
import { setTrackingReferer } from "utils/helper";
import InfoCircleIcon from "icons/InfoCircleIcon";

import CheckCircleIcon from "icons/CheckCircleIcon";
import CloseCircleIcon from "icons/CloseCircleIcon";
import formatCurrency from "utils/format-currency";
import Service from "../../services/home";
import Image from "components/image";
import useNotify from "hooks/useNotify";
import { CODE } from "services/http-common";
import { ReturnedData } from "apis/model";
import { setValueToSessionStorage } from "utils/local-storage";

const owlClass = "promotion-voucher-paywall";
const SOURCE_LOG = "paywall_voucher";
const FLOW_LOG = "Onboard Paywall Voucher";
const packageModalStyle = "package-modal";

type CouponInputState =
  | "none"
  | "applying"
  | "success"
  | "success_done"
  | "fail";

const COUPON_CODE = "ZBIZCHAO2025";
const DISCOUNT_VALUE = 200_000;
const BASE_PRICE = 1_990_000;

const PromotionVoucherPaywall: React.FC = () => {
  const navigate = useNavigate();
  const { profile, isLoadingProfile } = useAppSelector(
    (state: RootState) => state.profile
  );
  const initLogin = useRef<boolean>(false);

  const [statusCoupon, setStatusCoupon] = useState<CouponInputState>("none");
  const [couponCode, setCouponCode] = useState("");
  const [discountValue, setDiscountValue] = useState(0);
  const isRequestPaymentRef = useRef<boolean>(false);

  const { setShowNotifyModal, setShowProductsModal } = useNotify();

  const [showToastCopySuccess, setShowToastCopySuccess] = useState(false);
  const isShowingToastCopy = useRef(false);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleNavigate = (path: string, func?: () => void) => {
    navigate(path);
    func?.();
  };

  const onClickServiceAgreement = () => {
    home.trackingLog({
      flow: FLOW_LOG,
      action: "click_service_agreement",
      params: JSON.stringify({ source: SOURCE_LOG }),
    });
    handleNavigate("/service-agreement");
  };

  const handleErrorCheckValidPaywall = (resp: ReturnedData<any>) => {
    setValueToSessionStorage("referer", JSON.stringify({}));
    const { error_code = -2007 } = resp;
    switch (error_code) {
      case -2016:
        handleNavigate("/", () => {
          setShowProductsModal({
            title: "Pro",
            description: (
              <div className={packageModalStyle}>
                <div style={{ width: 430 }}>
                  <Text
                    size="xlg-28"
                    weight="semi-bold"
                    center
                    className={`${packageModalStyle}__basic-title`}
                  >
                    Chương trình đã kết thúc
                  </Text>
                  <Text center style={{ display: "block" }}>
                    Ưu đãi đã đạt giới hạn 200 lượt. Cùng đón chờ chương trình
                    tiếp theo của zBusiness nhé!
                  </Text>
                </div>
              </div>
            ),
            okBtn: (
              <Button
                type="primary"
                size="large"
                block
                key={"ok_btn"}
                onClick={() => {
                  setShowProductsModal(null);
                }}
              >
                Đã hiểu
              </Button>
            ),
          });
        });

        break;
      case -2013:
        handleNavigate("/", () => {
          setShowNotifyModal({
            description: (
              <div className="notify-modal">
                <Image
                  srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                  width={160}
                  height={160}
                />
                <Text
                  center
                  size="xlg-24"
                  className="notify-modal__title"
                  weight="three-quarters-bold"
                >
                  Thực hiện không thành công
                </Text>
                <Text center size="xlg-18">
                  Bạn đã mua gói ưu đãi zBusiness Pro trước đó
                </Text>
              </div>
            ),
          });
        });
        break;
      case -2017:
        handleNavigate("/", () => {
          setShowProductsModal({
            title: "Pro",
            description: (
              <div className={packageModalStyle}>
                <div style={{ width: 430 }}>
                  <Text
                    size="xlg-28"
                    weight="semi-bold"
                    center
                    className={`${packageModalStyle}__basic-title`}
                  >
                    Chương trình đã kết thúc
                  </Text>
                  <Text center style={{ display: "block" }}>
                    Cùng đón chờ ưu đãi tiếp theo của zBusiness nhé!
                  </Text>
                </div>
              </div>
            ),
            okBtn: (
              <Button
                type="primary"
                size="large"
                block
                key={"ok_btn"}
                onClick={() => {
                  setShowProductsModal(null);
                }}
              >
                Đã hiểu
              </Button>
            ),
          });
        });
        break;
      default:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đã có lỗi xảy ra!!!
              </Text>
              <Text center size="xlg-18">
                Vui lòng thử lại.
              </Text>
            </div>
          ),
        });
    }
  };

  const copyToClipboard = useCallback(() => {
    if (isShowingToastCopy.current) return;
    isShowingToastCopy.current = true;
    navigator.clipboard.writeText(COUPON_CODE);
    setShowToastCopySuccess(true);

    timeoutRef.current = setTimeout(() => {
      setShowToastCopySuccess(false);

      isShowingToastCopy.current = false;
    }, 2000);
    Service.trackingLog({
      flow: FLOW_LOG,
      action: "copy_voucher",
      params: JSON.stringify({ source: SOURCE_LOG }),
    });
  }, []);

  const applyCoupon = useCallback(async () => {
    if (!couponCode) return;
    setStatusCoupon("applying");

    try {
      const res = await Service.checkValidPromotion({
        productId: import.meta.env.VITE_PRODUCT_ID_PROMOTION,
        promotionId: couponCode,
        userId: profile?.userId || "",
      });

      const { error_code = -1 } = res;
      if (error_code === CODE.SUCCESS) {
        onApplySuccess();
      } else {
        onApplyFailed(error_code);
      }

      Service.trackingLog({
        flow: FLOW_LOG,
        action: "redeem_voucher",
        params: JSON.stringify({ source: SOURCE_LOG, error_code }),
      });
    } catch (error) {
      console.log("applyCoupon", error);
    }
  }, [couponCode]);

  const onApplySuccess = () => {
    setStatusCoupon("success");
    setDiscountValue(DISCOUNT_VALUE);
    setTimeout(() => {
      setStatusCoupon("success_done");
    }, 300);
  };

  const onApplyFailed = (code: number) => {
    setStatusCoupon("fail");

    const mapMessageByCode: Record<string, string> = {
      "-2015": "Mã giảm giá không đúng",
      "-2013": "Bạn đã sử dụng mã ưu đãi",
      "-2016": "Đã hết lượt ưu đãi",
      "113": "Thao tác quá nhanh, vui lòng thử lại",
    };

    const message = mapMessageByCode[`${code}`] || "Có lỗi xảy ra";

    setCouponCode(message);
  };

  const onCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode((e.target.value + "").toUpperCase());
  };

  const clearCouponCode = () => {
    setStatusCoupon("none");
    setCouponCode("");
    setDiscountValue(0);
  };

  const handleErrorNotificationPayment = (errorCode: number) => {
    switch (errorCode) {
      case -2001:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Nâng cấp gói zBusiness không thành công
              </Text>
              <Text center size="xlg-18">
                Tài khoản của bạn chưa đủ điều kiện để thực hiện việc nâng cấp
                lên gói zBusiness
              </Text>
            </div>
          ),
        });
        break;
      case -2003:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đăng ký không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn có thể chờ hết hạn gói hiện tại để đăng ký gói này
              </Text>
            </div>
          ),
        });
        break;
      case -2004:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/25/zds_il_user_account_ekyc_warning_1711354288375_317079.png"
                width={220}
                height={220}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Vui lòng thực hiện định danh tài khoản Zalo để tiếp tục
              </Text>
              <Text center size="xlg-18">
                Bạn cần mở ứng dụng Zalo trên thiết bị di động, kiểm tra tin
                nhắn từ <strong>OA Zalo Định Danh Tài Khoản</strong> và làm theo
                hướng dẫn.
              </Text>
            </div>
          ),
        });
        break;
      case -2005:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/25/zds_il_user_account_ekyc_warning_1711354288375_317079.png"
                width={220}
                height={220}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Vui lòng thực hiện lại định danh tài khoản Zalo để tiếp tục
              </Text>
              <Text center size="xlg-18">
                Bạn cần mở ứng dụng Zalo trên thiết bị di động, kiểm tra tin
                nhắn từ <strong>OA Zalo Định Danh Tài Khoản</strong> và làm theo
                hướng dẫn.
              </Text>
            </div>
          ),
        });
        break;
      case -2008:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Nâng cấp gói zBusiness không thành công
              </Text>
              <Text center size="xlg-18">
                Tài khoản của bạn đang sử dụng dịch vụ zStyle nên chưa thể nâng
                cấp zBusiness. Chúng tôi sẽ hỗ trợ trong thời gian sớm nhất.
              </Text>
            </div>
          ),
        });
        break;

      case -2009:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đăng ký không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn không nằm trong danh sách giới hạn được trải nghiệm chương
                trình
              </Text>
            </div>
          ),
        });
        break;
      case -2010:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Thực hiện không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn đã mua gói dùng thử zBusiness Pro trước đó
              </Text>
            </div>
          ),
        });
        break;
      case -2013:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Thực hiện không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn đã mua gói ưu đãi zBusiness Pro trước đó
              </Text>
            </div>
          ),
        });
        break;

      default:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đã có lỗi xảy ra!!!
              </Text>
              <Text center size="xlg-18">
                Vui lòng thử lại.
              </Text>
            </div>
          ),
        });
        break;
    }
  };

  const handleRequestPayment = () => {
    if (isRequestPaymentRef.current) return;
    isRequestPaymentRef.current = true;
    type PaymentRequest = {
      productId: string;
      userId: string;
      promotionId?: string; // Dấu "?" làm cho `promotionId` trở thành tùy chọn
    };

    const paymentRequest: PaymentRequest = {
      productId: import.meta.env.VITE_PRODUCT_ID_PROMOTION,
      userId: profile?.userId || "",
    };

    if (statusCoupon === "success_done" || statusCoupon === "success") {
      paymentRequest.promotionId = couponCode;
    }

    Service.requestProductPayment(paymentRequest)
      .then((resp) => {
        const { error_code = -1, data } = resp;
        isRequestPaymentRef.current = false;
        Service.trackingLog({
          flow: FLOW_LOG,
          action: "click_button_choose_package",
          params: JSON.stringify({
            source: SOURCE_LOG,
            error_code,
            type: "subscription",
            product_id: import.meta.env.VITE_PRODUCT_ID_PROMOTION,
            promotion_id: couponCode,
          }),
        });

        if (error_code === CODE.SUCCESS) {
          window.location.href = data || "https://business.zbox.vn/";
        } else {
          handleErrorNotificationPayment(error_code);
        }
      })
      .catch((err) => {
        isRequestPaymentRef.current = false;
        console.error("requestProductPayment", err);
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đã có lỗi xảy ra!!!
              </Text>
              <Text center size="xlg-18">
                Vui lòng thử lại.
              </Text>
            </div>
          ),
        });
      });
  };

  useEffect(() => {
    if (!initLogin.current) {
      setTrackingReferer();
      Service.trackingLog({
        flow: FLOW_LOG,
        action: "visit_paywall_business",
        params: JSON.stringify({ source: SOURCE_LOG }),
      });
      store.dispatch(
        onFetchProfile(
          `${import.meta.env.VITE_DOMAIN}/voucher`,
          {
            flow: FLOW_LOG,
            action: "sign_in_success",
            params: JSON.stringify({ source: SOURCE_LOG }),
          },
          true
        )
      );
      initLogin.current = true;
    }
  });

  useEffect(() => {
    if (!profile) return;

    home
      .checkValidPaywall({
        productId: import.meta.env.VITE_PRODUCT_ID_PROMOTION,
        promotionId: COUPON_CODE,
      })
      .then((resp) => {
        if (resp.error_code !== CODE.SUCCESS) {
          handleErrorCheckValidPaywall(resp);
        }
      })
      .catch((err) => {
        console.log("checkValidPaywall", err);

        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đã có lỗi xảy ra!!!
              </Text>
              <Text center size="xlg-18">
                Vui lòng thử lại.
              </Text>
            </div>
          ),
        });
      });
  }, [profile]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  if (
    (isLoadingProfile || !profile?.userId) &&
    window.navigator.userAgent.includes(PC_AGENT)
  ) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div
          style={{
            position: "fixed",
            width: "100vh",
            height: "100vh",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      </>
    );
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <Section
          name="paywall"
          style={{
            // position: "sticky",
            top: 0,
            zIndex: 9,
            background: "white",
          }}
          title={
            <Text size="xlg-28" weight="bold">
              Cơ hội chỉ 200 suất<br></br>Giảm 10% khi mua gói zBusiness Pro 12
              tháng
            </Text>
          }
          btmDescription={
            <div className={`${owlClass}-gift-code`}>
              <span className={`${owlClass}-gift-code__text`}>Nhập mã</span>
              <div
                className={`${owlClass}-copy-input`}
                onClick={copyToClipboard}
              >
                <span className={`${owlClass}-copy-input__code`}>
                  ZBIZCHAO2025
                </span>

                <div className={`${owlClass}-copy-input__icon`}>
                  <CopyIcon></CopyIcon>
                </div>
                <span
                  className={`${owlClass}-copy-input__toast ${
                    showToastCopySuccess
                      ? `${owlClass}-copy-input__toast--active`
                      : ""
                  }`}
                >
                  Sao chép thành công
                </span>
              </div>
            </div>
          }
        ></Section>
        <div className={`${owlClass}-content`}>
          <div className={`${owlClass}-pro-benefit`}>
            <div className={`${owlClass}-price-info`}>
              <span className={`${owlClass}-price-info__title`}>
                Gói Pro 12 tháng
              </span>
              <span className={`${owlClass}-price-info__price`}>
                1.990.000đ
              </span>
              <span className={`${owlClass}-price-info__detail`}>
                Chỉ 166.000đ/tháng
              </span>
            </div>
            <div style={{ marginTop: "24px" }}>
              <ListBenefitPro
                source={SOURCE_LOG}
                flow={FLOW_LOG}
              ></ListBenefitPro>
            </div>
          </div>
          <div className={`${owlClass}-payment`}>
            <span className={`${owlClass}-payment__temp-text`}>Tạm tính</span>
            <div
              className={`${owlClass}-price-detail`}
              style={{ marginTop: "20px" }}
            >
              <span className={`${owlClass}-price-detail__text`}>
                Thành tiền
              </span>
              <span className={`${owlClass}-price-detail__price`}>
                {formatCurrency("vi", BASE_PRICE)}
              </span>
            </div>
            <div className={`${owlClass}-payment__dot-line`}> </div>
            <div
              className={`${owlClass}-code-input 
              ${statusCoupon === "fail" && `${owlClass}-code-input--failed`}
              ${
                statusCoupon === "success_done" &&
                `${owlClass}-code-input--success_done`
              }`}
            >
              <input
                className={`${owlClass}-code-input__input`}
                placeholder="Nhập mã giảm giá"
                maxLength={20}
                value={couponCode}
                readOnly={statusCoupon !== "none"}
                onChange={onCouponChange}
              ></input>
              {statusCoupon === "none" && (
                <button
                  className={`${owlClass}-code-input__apply-btn ${
                    couponCode
                      ? `pointer `
                      : `no-pointer ${owlClass}-code-input__apply-btn--disabled`
                  }`}
                  onClick={applyCoupon}
                >
                  Áp dụng
                </button>
              )}
              {statusCoupon === "applying" && (
                <div>
                  <div className={`${owlClass}-code-input__spinner`}></div>
                </div>
              )}

              {statusCoupon === "success" && <CheckCircleIcon />}
              {["success_done", "fail"].includes(statusCoupon) && (
                <CloseCircleIcon
                  onClick={clearCouponCode}
                  className="pointer"
                />
              )}
            </div>
            <div
              className={`${owlClass}-price-detail`}
              style={{ marginTop: "12px" }}
            >
              <span className={`${owlClass}-price-detail__text`}>Giảm giá</span>
              <span className={`${owlClass}-price-detail__price`}>
                -{formatCurrency("vi", discountValue)}
              </span>
            </div>

            <div className={`${owlClass}-payment__solid-line`}></div>

            <div
              className={`${owlClass}-price-detail`}
              style={{ marginTop: "16px" }}
            >
              <span className={`${owlClass}-price-detail__text`}>
                Tổng thanh toán
              </span>
              <span className={`${owlClass}-price-detail__price`}>
                {formatCurrency("vi", BASE_PRICE - discountValue)}
              </span>
            </div>

            <Button
              type="primary"
              block
              size="regular"
              style={{ marginTop: "32px", marginBottom: "28px" }}
              onClick={handleRequestPayment}
            >
              Đến trang thanh toán
            </Button>

            <div className={`${owlClass}-note`}>
              <div className={`${owlClass}-note__icon`}>
                <InfoCircleIcon></InfoCircleIcon>
              </div>
              <p className={`${owlClass}-note__text`}>
                Thời hạn gói sẽ được cộng dồn nếu bạn đã đăng ký gói Pro
              </p>
            </div>

            <div></div>
          </div>
        </div>
      </Layout>
      <div className={`${owlClass}__service-agreement`}>
        Khi sử dụng dịch vụ, bạn cũng đồng ý với{" "}
        <span onClick={onClickServiceAgreement}>Thoả thuận sử dụng</span> dịch
        vụ zBusiness.
      </div>
    </div>
  );
};

export default PromotionVoucherPaywall;
