import { IconProps } from "./model";

const CloseCircleIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "20"}
    height={size || "20"}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.37497 10C1.37497 5.23654 5.23651 1.375 9.99997 1.375C14.7634 1.375 18.625 5.23654 18.625 10C18.625 14.7635 14.7634 18.625 9.99997 18.625C5.23651 18.625 1.37497 14.7635 1.37497 10ZM6.59464 6.59467C6.88753 6.30178 7.36241 6.30178 7.6553 6.59467L9.99997 8.93934L12.3446 6.59467C12.6375 6.30178 13.1124 6.30178 13.4053 6.59467C13.6982 6.88756 13.6982 7.36244 13.4053 7.65533L11.0606 10L13.4053 12.3447C13.6982 12.6376 13.6982 13.1124 13.4053 13.4053C13.1124 13.6982 12.6375 13.6982 12.3446 13.4053L9.99997 11.0607L7.6553 13.4053C7.36241 13.6982 6.88753 13.6982 6.59464 13.4053C6.30175 13.1124 6.30175 12.6376 6.59464 12.3447L8.93931 10L6.59464 7.65533C6.30175 7.36244 6.30175 6.88756 6.59464 6.59467Z"
      fill={props.color || "#909498"}
    />
  </svg>
);

export default CloseCircleIcon;
